import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import Card from "../components/card"
import Container from "../components/container"
// import Carousel from "../components/carousel"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="experience" />
      <h2 style={{ marginTop: `1rem` }}>[things i've made and things i do]</h2>
      <p><i>i am aware that this website looks rather basic—it will be revamped soon, i swear!</i></p>

      <h3>projects</h3>
      <Container
        title="symbia"
        desc="a prototype of an iOS app that incentivizes people to recycle"
        descAbout="ranked in top 10 of 300 adobe xd projects nationwide @ adobe x target creative jam"
        imgLocation="/experiences/symbia.png"
        link="https://xd.adobe.com/view/81e42596-b424-47e5-81c6-44f31bdb664b-d4b7/"
        // learnings="this project took a lot of the product thinking strategies and design frameworks that 
        // i'd learned about through other work into account: writing high-level problem statements, defining 
        // user personas, and mocking up lo-fi wireframes. conducted usability testing and updated new iterations 
        // with external feedback. it was a very fun project because it let me think critically from the perspective
        // of the user and always reminding myself of what their needs are."
      ></Container>

      <Container
        title="covalent"
        desc="a web app for remote team bonding"
        descAbout="worked on frontend (react, next), marketing (socials, email), and design (figma), eventually launched on product hunt"
        imgLocation="/experiences/covalent.png"
        link="https://covalent.app/"
        // learnings="one of my first web dev projects, ever. it's also one that i look back on very fondly because of 
        // just how much i'd learned through the process—this project represents the first time i used figma and react, 
        // and gave me an opportunity to build a beta tester group and conduct user/market research."
      ></Container>

      <Container
        title="terra"
        desc="a composting web app prototyped @ innovate@ucla"
        descAbout="presented and pitched to other teams at this program"
        imgLocation="/experiences/terra.png"
        link="https://www.figma.com/proto/B0L3QPXrUq8pMyfb5mPHQI/Prototypes?node-id=277%3A80&starting-point-node-id=277%3A80"
        // learnings="this was a huge project for learning to ideate, design, and plan with a process. as one of the larger 
        // teams i've worked on, i incorporated more industry practices into our team workflow, including jira for task management 
        // and weekly standups."
      ></Container>

      <Container
        title="ghostracer"
        desc="a driving game involving zombies, rewards, and pedestrians, developed @ ucla"
        descAbout="built entirely in c++ and explores OOP and inheritance concepts"
        imgLocation="/experiences/ghostracer.png"
        link="https://github.com/catherinehhu/GhostRacer"
        // learnings="the spec was intense. this taught me to break down dev tasks into smaller and more digestible chunks, 
        // as well as building up good documentation for different functions and for testing/debugging processes."
      ></Container>

      <Container
        title="ios apps"
        desc="modified versions of instagram, twitter, and imdb built @ ios codepath course fall 2020"
        descAbout="used swift ui and existing apis to pull real-time data into my version of these apps"
        imgLocation="/experiences/flix.png"
        link="https://github.com/catherinehhu/flix"
        // learnings="downloading xcode absolutely made my computer overheat. but it was worth it. i don't really get 
        // to do a lot of mobile dev/limited opportunities to do so, but this project was so cool for me to learn and experience. 
        // plus, i met a lot of people in my classes along the way. "
      ></Container>

      {/* <Container
        title="designerth"
        desc="a prototype of a design-education tablet app (top 25) @ adobe x amazon creative jam"
        imgLocation="/experiences/designerth.png"
        link="https://xd.adobe.com/view/908b75b8-9f91-48ae-8ef2-04fcd102081f-c5d8/"
      ></Container> */}

      <Container
        title="Daily Bruin"
        imgLocation="/experiences/ucla.png"
        desc="data journalist for the stack and writer for the news page (science & health beat)"
        descAbout="conducted interviews, wrote articles, and created data visualizations and graphics using tools like mapbox and d3.js"
        link="https://dailybruin.com/author/catherine-hu"
        // learnings="daily bruin allowed me to continue exploring my passion for writing while learning more about how to tell data-driven stories."
      ></Container>

      <Container
        title="beckman spectrophotometer"
        desc="a functional spectrophotometer built @ the arnold & mabel beckman foundation"
        descAbout={"awarded second place and a $12k college scholarship for analysis and research of synthetic food dyes"}
        imgLocation="/experiences/beckman.png"
        link="https://www.beckman-foundation.org/people/catherine-hu/"
        // learnings="honestly, i signed up for this on a whim. while it's not a tech/design project, building something physical was alos
        // a challenge because of the precision involved. can't tell you how many times i took it apart and rebuilt it for it to work. "
      ></Container>

      <h3>school orgs</h3>
      <p>at ucla, you can find me @ la hacks, nova for good, or upe. i run around campus a lot.</p>
      <Container
        title="LA Hacks"
        imgLocation="/experiences/lahacks.png"
        desc="leading one of the largest collegiate hackathons in the united states + celebrating our 11th iteration this april"
        descAbout={"executive director (22-23) | sponsorships director (21-22)"}
        link="https://lahacks.com/"
      ></Container>

      <Container
        title="Nova For Good"
        imgLocation="/experiences/nova.png"
        desc="connecting students in tech with nonprofits to design meaningful solutions and serve communities"
        descAbout={"director, product manager, and developer"}
        link="https://www.novaforgood.org/"
  ></Container>

    <Container
        title="UPE"
        imgLocation="/experiences/upe.png"
        desc="making tech more accessible for the ucla community with the mentorship committee"
        descAbout={"mentorship officer"}
        link="https://upe.seas.ucla.edu/"
      ></Container>

      <h3>work</h3>
      <ul>
        <li>pm @ lucid software (june 2022 - sept 2022)</li>
        <li>researcher @ ucla anderson school of management (sept 2021 - present)</li>
        <li>researcher @ the counterforce lab (jan - june 2022)</li>
        <li>fintech wintern @ marketaxess (jan 2022)</li>
        <li>pm @ rising academies (sept - nov 2021)</li>
        <li>pmm @ hoamsy (june - aug 2021)</li>
        <li>pm + swe @ urx (june - aug 2021)</li>
        <li>sustainability analyst @ voiz (jan - march 2021)</li>
        <li>content writer @ city flavor (july - oct 2020)</li>
      </ul>


    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
