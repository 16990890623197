import * as React from 'react'

const Container = ({desc, descAbout, learnings, title, imgLocation, link, children}) => {
  return (
      <div>
        <div className = "container"> 
          <a href = {link}><img className = "container-img" src={imgLocation} style = {{display: 'flex',  justifyContent:'center', alignItems:'center', width: `15vw`, minHeight: `25vh`, objectFit: `contain`}}/></a>
          <div className = "vertical-line" ></div>
          <div><ul><h4>{title}</h4>
            <li>{desc}</li>
            <li>{descAbout}</li>
            </ul></div>
        </div>
        <div>
          <p><i>{learnings}</i></p>
        </div>
      </div>
        
  )
}

export default Container